<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Vendor</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.supplier_name }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>NPWP</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.npwp }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Address 1</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.address_1 }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Address 2</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.address_2 }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Contact Name</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.contact_name }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Phone</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.phone }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Email</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.email }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Position</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.position }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-vendor",
  props: {
    form: Object,
  },
};
</script>

<style></style>
