<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit :form="item" ref="formEdit" v-if="!edit"></form-edit>
        <detail :form="item" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import Detail from "./DetailVendor";
import FormEdit from "./FormVendor";

export default {
  name: "vendor-view",
  components: {
    Detail,
    "form-edit": FormEdit,
  },
  props: {
    id: Number,
  },
  data: () => ({
    edit: true,
    item: {},
  }),
  methods: {
    async getVendor() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getVendorById", this.id)
        .then(response => {
          this.item = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getVendor();
      this.edit = true;
    },
  },
  mounted() {
    this.getVendor();
  },
};
</script>

<style></style>
